
@import '../../vars';

header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    #HeaderTop{
        background-color: $color-kia-blue;
        width: 100%;
        padding: 13px;
    }
    #HeaderLogo{
        position: relative;
        #HeaderMainMenuLeft,
        #HeaderMainMenuRight{
            position: absolute;
            left: 0;
            top: 0;
            #MobileMainMenuButton{
                span{
                    display: block;
                    width: 100%;
                    height: 3px;
                    background-color: black;
                    margin-bottom: 5px;
                    font-family: 'KiaSignatureFix';
                    font-weight: bold;
                }
                background-color: white;
                width: 55px;
                cursor: pointer;
                display: none;
                padding: 20px  0 0 20px;
                @media (max-width: $mobile-width) {
                    display: block;
                }
            }
            ul.main-listbuttons{
                display: flex;
                padding: 20px 0 0 20px;
                @media (max-width: $mobile-width) {
                    padding: 20px 0 10px 0;
                    display: none;
                    &.open{
                        position: fixed;
                        width: 100%;
                        display: block !important;
                        background-color: white;
                    }
                    &.close{
                        display: none;
                    }
                }
                li.nav-item{
                    cursor: pointer;
                    background: none;
                    &.faq-item{
                        display: none;
                        @media (max-width: $mobile-width) {
                            display: block;
                        }
                    }
                    &.nav-submenu{
                        &:hover{
                            button{
                                background: url(../../assets/images/footer-arrow-black_up.png) no-repeat 99% 30%;
                                background-size: 9%;
                                padding-right: 26px;
                                padding-bottom: 17px;
                                border-bottom: 2px solid #05141f;
                                
                                @media (max-width: $mobile-width) {
                                    background: url(../../assets/images/footer-arrow-black.png) no-repeat 95% 45%;
                                    background-size: 3%;
                                    padding-right: 0;
                                    border-bottom: 1px solid rgb(205, 205, 205);
                                    padding-bottom: 10px;
                                }
                            }
                            ul{
                                background-color: white;
                                display: flex;
                                width: 100%;
                                position: fixed;
                                left: 0;
                                top: 91px;
                                @media (max-width: 1100px) {
                                    margin-left: 0;
                                    position: inherit;
                                    display: block;
                                }
                                button{
                                    background: none;
                                    padding: 15px 25px;
                                    border: none;
                                    &:hover{
                                        border-bottom: 2px solid #05141f;
                                        @media (max-width: $mobile-width) {
                                            background-color: rgb(231, 231, 231);
                                            border-bottom: 1px solid rgb(205, 205, 205);
                                        }
                                    }
                                    @media (max-width: $mobile-width) {
                                        background-color: rgb(231, 231, 231);
                                        border-bottom: 1px solid rgb(205, 205, 205);
                                        padding: 10px 0;
                                    }
                                }
                            }
                        }
                        button{
                            background: url(../../assets/images/footer-arrow-black.png) no-repeat 99% 30%;
                            background-size: 9%;
                            padding-right: 26px;
                            padding-bottom: 17px;
                            @media (max-width: 1100px) {
                                padding-right: 20px;
                            }
                            @media (max-width: $mobile-width) {
                                padding: 10px 0;
                                border-bottom: 1px solid rgb(205, 205, 205);
                                width: 90%;
                                margin: 0 auto;
                                background-size: 3%;
                            }
                        }
                        ul{
                            display: none;
                            padding-left: 50px;
                            @media (max-width: $mobile-width) {
                                display:block;
                                padding-left: 0;
                                button{
                                    background: none;
                                    background-color: rgb(231, 231, 231);
                                }
                            }
                            button{
                                span{
                                    font-size: 1.3rem;
                                }
                            }
                        }
                    }
                    button{
                        padding: 5px 25px;
                        cursor: pointer;
                        display: block;
                        background: none;
                        padding-bottom: 17px;
                        @media (max-width: 1100px) {
                            padding: 5px 10px;
                        }
                        &:hover{
                            border-bottom: 2px solid #05141f;
                        }
                        @media (max-width: $mobile-width) {
                            padding: 10px 0;
                            border-bottom: 1px solid rgb(205, 205, 205);
                            width: 90%;
                            margin: 0 auto;
                            &:hover{
                                border-bottom: 1px solid rgb(205, 205, 205);
                            }
                        }
                    }
                    span{
                        font-size: 14px;
                        background: none;
                        font-family: 'KiaSignatureFix';
                        font-weight: bold;
                        color: #05141f;
                        font-size: 1.4rem;
                    }
                }
            }
        }
        width: 100%;
        padding: 20px;
        border-bottom: 2px solid #bfbfbf;
        background-color: $color-kia-white;
        #MainLogo{
            cursor: pointer;
            max-width: 100px;
            display: block;
            margin: 0 auto;
            background: none;
        }
        #HeaderMainMenuRight{
            left: inherit;
            right: 0;
            @media (max-width: 1100px) {
                display: none;
            }
        }
    }
}