@import '../../vars';

.intro-component{
    padding: 50px 0;
    display: flex;
    &:first-child{
        padding-top: 0;
    }
    &.back-order{
        flex-direction: row-reverse;
    }
    @media (max-width: $mobile-width) {
        display: block;
    }
    .panel-side{
        width: 50%;
        @media (max-width: $mobile-width) {
            width: 100%;
        }
    }
    .image-intro{
        padding: 0 20px;
        height: 350px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (max-width: $mobile-width) {
            padding: 0;
        }
    }
    .info-component{
        padding: 0 20px;
        h2{
            font-family: 'kiaB';
            font-size: 30px;
            padding: 20px 0;
            @media (max-width: $mobile-width) {
                font-size: 24px;
            }
        }
        h3{
            font-size: 22px;
            padding-bottom: 20px;
            @media (max-width: $mobile-width) {
                font-size: 18px;
            }
        }
        a.maps-link{
            display: block;
            padding: 20px 0;
            font-size: 14px;
        }
        p{
            font-size: 19px;
            padding-bottom: 20px;
            @media (max-width: $mobile-width) {
                font-size: 16px;
            }
        }
        @media (max-width: $mobile-width) {
            padding: 20px;
            text-align: center;
        }
    }
}