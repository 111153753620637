@import '../../vars';
@import '~leaflet/dist/leaflet.css';

#DetailMainContainer{
    margin-top: 91px;
    @media (max-width: $mobile-width) {
        margin-top: 91px;
    }
    #MainTitle{
        h1{
            padding: 70px 0;
            font-size: 43px;
            text-align: center;
            line-height: 6rem;
            span{
                font-size: 37px;
            }
            @media (max-width: $mobile-width) {
                line-height: 3rem;
                padding: 40px 0;
                font-size: 36px;
                span{
                    font-size: 28px;
                }
            }
        }
    }
    .intro-text{
        max-width: 800px;
        display: block;
        margin: 0 auto;
        padding: 50px 0;
        text-align: center;
        h1{
            font-family: 'kiaB';
            font-size: 35px;
            padding-bottom: 40px;
        }
        h2{
            font-size: 28px;
            padding-bottom: 40px;
        }
        P{
            padding-bottom: 20px;
            font-size: 21px;
        }
        @media (max-width: $mobile-width) {
            padding: 20px;
        }
    }
    .RehabilitedZones{
        background-color: black;
        background-position: center center;
        background-size: cover;
        position: relative;
        width: 100%;
        padding: 150px 0;
        @media (max-width: $mobile-width) {
            padding: 50px 0;
        }
        .description-rehab{
            font-size: 20px;
            margin: 0 auto;
            width: 80%;
            max-width: 600px;
            color: white;
            text-align: center;
            left: 0;
            right: 0;
            line-height: 3rem;
            top: 0;
            @media (max-width: $mobile-width) {
                h1{
                    font-size: 28px !important;
                }
            }
        }
    }
    .intro-text01{
        width: 90%;
        max-width: inherit;
        padding: 80px 0;
        .intro-left{
            padding-right: 50px;
            P{
                font-size: 21px;
            }
        }
    }
    ul{
        li{
            font-size: 19px;
        }
    }
    .invitation-cont{
        padding: 80px 0;
        text-align: center;
        background-color: $color-kia-gray2;
        h3{
            font-family: 'Noto Sans';
            font-size: 35px;
            font-weight: lighter;
        }
        .more-btn{
            margin: 50px auto 0 auto;
        }
    }
    #GalleryContainer{
        padding: 50px;
        background-color: $color-kia-white;
        @media (max-width: $mobile-width) {
            padding: 10px;
        }
        p{
            padding: 40px;
            font-size: 15px;
            font-family: kiaM;
            font-weight: lighter;
            line-height: 25px;
            color: black;
            @media (max-width: $mobile-width) {
                padding: 20px 0;
                text-align: center !important;
            }
        }
        @media (max-width: $mobile-width) {
            h1{
                font-size: 28px !important;
                padding-bottom: 50px !important;
            }
        }
        .ItemCont{
            width: 90%;
            margin: 0 auto;
            padding:  0 0 100px 0;
            display: flex;
            border-bottom: 1px solid gray;
            div, .rsis-image{
                background-position: center bottom;
            }
            &.reverse{
                flex-direction: row-reverse;
            }
            &:first-child{
                padding-top: 0;
            }
            @media (max-width: $mobile-width) {
                display: block;
                padding-bottom: 0;
            }
            div.left,
            div.right{
                width: 50%;
                height: 500px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 200px;
                h2{
                    margin-bottom: 50px;
                    padding: 0 20px;
                    font-size: 32px;
                    color: black;
                    width: 80%;
                    margin: 0 auto 30px auto;
                    @media (max-width: $mobile-width) {
                        font-size: 24px !important;
                    }
                }
                p{
                    margin: 0 auto;
                    padding: 0 20px;
                    font-size: 17px;
                    width: 80%;
                    color: black;
                }
                @media (max-width: $desktop-width) {
                    height: 300px;
                }
                @media (max-width: $mobile-width) {
                    display: block;
                    width: 100%;
                    height: inherit;
                    h2{
                        padding: 0 20px;
                        font-size: 32px;
                        color: black;
                        width: 100%;
                        text-align: center;
                        margin: 20px auto;
                    }
                    p{
                        text-align: center;
                        margin: 0 auto;
                        padding: 20px;
                        font-size: 17px;
                        width: 100%;
                    }
                }
            }
        }
        #MainGallery{
            display: block;
            width: 90%;
            height: 900px;
            margin: 50px auto 0 auto;
            position: relative;
            div, .rsis-container{
                background-position: center bottom;
            }
            @media (max-width: $desktop-width) {
                height: 700px;
            }
            @media (max-width: $mobile-width) {
                height: 200px;
                margin-bottom: 50px;
            }
        }
    }
    #MapContainer{
        width: 600px;
        height: 300px;
        display: block;
        margin: 0 auto;
        @media (max-width: $mobile-width) {
            width: 100%;
            height: auto;     
        }
        .leaflet-container {
            width: 100%;
            height: 100%;
            z-index: 1;
            @media (max-width: $mobile-width) {
                height: 200px;
            }
        }
    }
    #SelectOther{
        background-color: #eee;
        padding: 100px 0;
        font-size: 30px;
        font-family: kiaM;
        p{
            text-align: center;
        }
        button{
            cursor: pointer;
            display: block;
            margin: 30px auto 0 auto;
            padding: 20px;
            font-size: 20px;
            font-family: kiaM;
            background: none;
            border: 1px solid #000;
            &:hover{
                background-color: white;
            }
        }
    }
}