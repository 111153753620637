@import "./vars";

html,
body,input,textarea,select,table{
    margin: 0;
    padding: 0;
    font-family: 'kiaM','Noto Sans';
    font-size: 12px;
}
p,span, h1, h2, h3, h4{
    font-family: 'kiaM','Noto Sans'
}